import { isAboveAuthority } from 'helpers';
import { useMyAuthority } from 'helpers/hooks';
import React, { useEffect, useState } from 'react';
import { Route, useLocation, useParams, useHistory } from 'react-router-dom';
import authHandler from '../helpers/authHandler';
import AppLayout from './AppLayout';

const AppRoute = ({
  component: Component,
  layout: { header, footer } = { header: false, footer: false },
  authProtected,
  lazy,
  loader,
  prefetch,
  path,
  prefetchOnce,
  ...rest
}: {
  component: React.ReactElement,
  layout: { header: Boolean, footer: Boolean },
  authProtected: Boolean | any,
  lazy: Boolean,
  prefetch: (
    location: Location,
    params: Array<{}>,
    history: History,
    setRejector: (any) => void,
  ) => Promise<any>,
  loader: any,
  path: string,
  prefetchOnce: boolean,
} = {}) => {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const myAuthority = useMyAuthority(location.pathname.split('/')[2]);

  const [dataLoaded, setDataLoaded] = useState(!prefetch);
  const [prefetchedIndex, setPrefetchedIndex] = useState([]);
  const [prefetchRejector, setPrefetchRejector] = useState(null);

  useEffect(() => {
    if (prefetchOnce && prefetchedIndex.includes(path)) {
      return;
    }
    setDataLoaded(!prefetch);

    if (prefetchRejector) {
      prefetchRejector();
    }

    if (prefetch) {
      prefetch(location, params, history, setPrefetchRejector).then((data) => {
        setDataLoaded(data);

        if (prefetchOnce) {
          setPrefetchedIndex([...prefetchedIndex, path]);
        }
      });
    }
  }, [path]);

  return (
    <Route
      {...rest}
      path={path}
      render={(props: { location: String }) => {
        if (authProtected && !authHandler.user) {
          window.location.href = '/signIn';
        }

        if (
          authProtected &&
          authProtected.type === 'authority' &&
          !isAboveAuthority(myAuthority, authProtected.authority)
        ) {
          window.location.href = `/dashboard/${
            location.pathname.split('/')[2]
          }`;
        }

        return (
          <AppLayout
            forceShowLoader={!dataLoaded}
            loader={loader}
            header={header}
            footer={footer}
            lazy={lazy}
          >
            <Component {...props} loader={loader} prefetchData={dataLoaded} />
          </AppLayout>
        );
      }}
    />
  );
};

export default AppRoute;
