import 'core-js/stable';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React, { Suspense, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import {
  StylesProvider,
  ThemeProvider as MUIThemeProvider,
} from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { RecoilRoot } from 'recoil';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { Button, createTheme, CssBaseline } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { languageFontFamilies, MUITheme, originTheme } from 'styles/theme';
import apolloClient from 'helpers/apolloClient';
import i18n from 'helpers/i18n';
import './index.css';
import { NeedConfirmProvider } from 'context/ConfirmAction';
import AppRoute from 'routes/AppRoute';
import Loading from 'components/ui/Loading';

const Concierge = React.lazy(() => import('./containers'));
const CheckIn = React.lazy(() => import('./containers/checkIn'));

moment.locale('ko');

class ModifiedUtils extends MomentUtils {
  constructor(props) {
    super(props);
    this.getMeridiemText = (ampm) =>
      ampm === 'am'
        ? moment.localeData().meridiem(0)
        : moment.localeData().meridiem(13);
  }
}

function App() {
  const [currentMuiTheme, setMuiTheme] = useState(MUITheme);
  const [momentLocale, setMomentLocale] = useState('ko');

  const onLanguageChange = useCallback(
    (language) => {
      try {
        const createdMuiTheme = createTheme({
          ...currentMuiTheme,
          typography: {
            ...originTheme.typography,
            ...(languageFontFamilies[language] || languageFontFamilies.default),
          },
        });
        setMuiTheme(createdMuiTheme);

        let momentLanguage = language;

        if (language === 'ko-kr') {
          momentLanguage = 'ko';
        }

        setMomentLocale(momentLanguage);

        moment.locale(momentLanguage);
      } catch (err) {
        console.log(err);
      }
    },
    [currentMuiTheme],
  );

  useEffect(() => {
    i18n.on('initialized', () => {
      onLanguageChange(i18n.language);
    });
    i18n.on('languageChanged', () => {
      onLanguageChange(i18n.language);
    });
  }, [onLanguageChange]);

  const notistackRef = React.createRef();
  const onClickDismiss = (key) => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <>
      <Helmet>
        <meta
          name="theme-color"
          content={currentMuiTheme.palette.primary.main}
        />
      </Helmet>
      <StylesProvider injectFirst>
        <Suspense fallback={Loading}>
          <RecoilRoot>
            <ApolloProvider client={apolloClient}>
              <MuiPickersUtilsProvider
                libInstance={moment}
                utils={ModifiedUtils}
                locale={momentLocale}
              >
                <MUIThemeProvider theme={currentMuiTheme}>
                  <StyledThemeProvider
                    theme={{ ...currentMuiTheme, custom: originTheme }}
                  >
                    <NeedConfirmProvider>
                      <SnackbarProvider
                        maxSnack={3}
                        autoHideDuration={4000}
                        ref={notistackRef}
                        action={(key) => (
                          <Button onClick={() => onClickDismiss(key)}>
                            닫기
                          </Button>
                        )}
                      >
                        <CssBaseline>
                          <BrowserRouter>
                            <Switch>
                              {/* 예약 QR 체크인 페이지 */}
                              <Route
                                component={CheckIn}
                                path="/checkIn/:reservationId"
                              />
                              <AppRoute
                                component={Concierge}
                                lazy
                                path="/concierge/:reservationId"
                              />
                            </Switch>
                          </BrowserRouter>
                        </CssBaseline>
                      </SnackbarProvider>
                    </NeedConfirmProvider>
                  </StyledThemeProvider>
                </MUIThemeProvider>
              </MuiPickersUtilsProvider>
            </ApolloProvider>
          </RecoilRoot>
        </Suspense>
      </StylesProvider>
    </>
  );
}

export default App;
