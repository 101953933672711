import { ApolloClient, InMemoryCache } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';

import {
  getMainDefinition,
  Observable,
  relayStylePagination,
} from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { ApolloLink, split } from '@apollo/client/link/core';
// import { fromPromise } from '@apollo/client/link/utils';
import { createUploadLink } from 'apollo-upload-client';
import { getErrorCode } from 'helpers';
import { API_HOST, WS_API_HOST } from './constants';

export const apolloCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getAccommodationNotifications: relayStylePagination([
          'accommodationId',
          'filter',
        ]),
        getAccommodationPayments: relayStylePagination([
          'accommodationId',
          'filter',
        ]),
        getAccommodationReservations: relayStylePagination([
          'accommodationId',
          'filter',
        ]),
        getAccommodationMileages: relayStylePagination([
          'accommodationId',
          'filter',
        ]),
        getAccommodationMileageUsers: relayStylePagination([
          'accommodationId',
          'filter',
        ]),
        getAccommodationUsers: relayStylePagination([
          'accommodationId',
          'filter',
        ]),
        getUserLogs: relayStylePagination(['accommodationId', 'relatedId']),
        getAccommodationCoupons: relayStylePagination(['accommodationId']),
        getArticles: relayStylePagination([]),
        getMyUserNotifications: relayStylePagination(['accommodationId']),
      },
    },
    Accommodation: {
      fields: {
        upcomingReservations: {
          merge: (existing, incoming) => incoming,
        },
      },
    },
    Kiosk: {
      fields: {
        options: {
          merge: (existing, incoming) => ({ ...existing, ...incoming }),
        },
      },
    },
    RoomType: {
      fields: {
        rooms: {
          merge: (existing, incoming) => incoming,
        },
      },
    },
  },
});

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle;
      Promise.resolve(operation)
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    }),
);

const wsLink = new WebSocketLink({
  uri: `${WS_API_HOST}/graphql`,
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: () => ({
      // Authorization: `Bearer ${authHandler.accessToken}`,
    }),
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  createUploadLink({
    uri: `${API_HOST}/graphql`,
  }),
);

const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          );
        });
      }
      if (networkError) {
        console.log('[Network error]: ', networkError);
      }
    }),
    requestLink,
    splitLink,
  ]),
  cache: apolloCache,
  defaultOptions: {
    query: {
      fetchPolicy: 'cache-first',
      errorPolicy: 'all',
    },
  },
});

export default apolloClient;
