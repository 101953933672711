import React from 'react';
import {
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle, Fade, Modal,
} from '@material-ui/core';
import styled from 'styled-components';
import STYLED_MACROS from 'styles/styledMacros';
import RopePattern from 'images/vendit_rope_pattern.svg';
import { ReactComponent as VenditRopeStart } from 'images/vendit_rope_start.svg';
import { ArrowForwardIos, ChevronLeft, Close } from '@material-ui/icons';
import { LayoutFlex } from 'components/ui/Layouts';
import { DefaultCard } from 'components/ui/Card';
import { TitleTypo } from 'components/ui/Typographies';
import { DarkButton, ErrorButton } from 'components/ui/Button';
import { useDeviceQuery } from 'helpers/hooks';
import { MobilePopup } from 'components/PopupPage';

const VenditModalBody = styled.div`
  background: ${STYLED_MACROS.BACKGROUND.LIGHT};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 480px;
  position: relative;
  outline: none;
  border-radius: 8px;
  width: calc(100% - ${(props) => props.theme.spacing(4)}px);
  max-width: 1200px;
`;

const ModalLayout = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const VenditModal = ({
  open, onClose, children, bodyProps, ...rest
}: { open: Boolean, onClose: Function, bodyProps: any, children: React.ComponentElement }) => {
  const isMobile = useDeviceQuery('mobile');

  if (isMobile) {
    return (
      <MobilePopup
        open={open}
        onClose={onClose}
        title="asd"
      >
        {
          children
        }
      </MobilePopup>
    );
  }

  return ((
    <ModalLayout
      open={open}
      onClose={onClose}
      closeAfterTransition
      {...rest}
    >
      <Fade in={open}>
        <VenditModalBody {...bodyProps}>
          {
            children
          }
        </VenditModalBody>
      </Fade>
    </ModalLayout>
  ));
};

const VenditModalHeaderWrapper = styled(LayoutFlex)`
  width: 100%;
  height: 80px;
  padding: 0;
  position: relative;
  background: ${STYLED_MACROS.BACKGROUND.DEFAULT};
  border-radius: 8px 8px 0px 0px;
  overflow: hidden;
  background-color: ${(props) => STYLED_MACROS.PALETTE.TYPE_SEPERATED(props, { background: true })};
  flex-shrink: 0;

  ${STYLED_MACROS.MEDIA_QUERY.MOBILE} {
    border-radius: 0;
    height: 64px;
  }
`;

const VenditModalHeaderLayout = styled.div`
  height: 100%;
  padding: 24px;
  display: flex;
  flex-shrink: 0;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  > svg {
    position: absolute;
    right: 24px;
    font-size: 2rem;
    cursor: pointer;
  }

  ${STYLED_MACROS.MEDIA_QUERY.MOBILE} {
    padding-left: 48px;

    > svg {
      right: unset;
      left: 8px;
    }
  }

`;

const VenditRopeBackground = styled.div`
  flex-grow: 1;
  height: 100%;
  background: url(${RopePattern});
  background-size: contain;
`;

export const VenditModalHeader = ({
  onClose, children, color, variant, ...rest
}) => {
  const isMobile = useDeviceQuery('mobile');
  return (
    <VenditModalHeaderWrapper type={color} data-variant={variant}>
      <VenditModalHeaderLayout>
        {children}
        {
          onClose
          && (
            isMobile
            && <ChevronLeft onClick={onClose} />
            || <Close onClick={onClose} />)
        }
      </VenditModalHeaderLayout>
      {
        variant === 'room'
        && (
        <>
          <VenditRopeStart style={{ height: '100%', width: 'auto' }} />
          <VenditRopeBackground />
        </>
        )
      }
    </VenditModalHeaderWrapper>
  );
};

const VenditModalFooterLayout = styled.div`
  width: 100%;
  height: 80px;
  background: ${STYLED_MACROS.BACKGROUND.DEFAULT};
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  position: relative;

  border-radius: 0px 0px 8px 8px;

  ${STYLED_MACROS.MEDIA_QUERY.MOBILE} {
    display: none;
  }
`;

export const VenditModalFooter = ({ children, ...rest }) => (
  <VenditModalFooterLayout>{children}</VenditModalFooterLayout>
);

export const VenditModalContent = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  min-height: 48px;
  flex-grow: 1;
  overflow: auto;

  > div {
    display: flex;
    flex-direction: column;
  }


  ${STYLED_MACROS.MEDIA_QUERY.NOT_MOBILE} {
    > div:first-child {
      width: 360px;
      padding-right: 24px;
      flex-shrink: 0;
      position: relative;
      &::before {
        content: '';
        width: 1px;
        background: ${STYLED_MACROS.PALETTE.BORDER.DEFAULT};
        position: absolute;
        top: 4px;
        bottom: 4px;
        right: 0;
      }
    }

    > div + div {
      padding-left: 24px;
      flex-grow: 1;
    }
    max-height: 70vh;
  }

  ${STYLED_MACROS.MEDIA_QUERY.MOBILE} {
    flex-direction: column;
    overflow: auto;
    > div {
      flex-grow: 0;
      flex-shrink: 0;
    }

    > div + div {
      margin-top: 24px;
      padding-top: 24px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -24px;
        width: calc(100% + 48px);
        height: 8px;
        background: ${STYLED_MACROS.BACKGROUND.DEFAULT};
      }
    }
  }
`;

export const MobileModalDivider = styled.div`
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -24px;
    width: calc(100% + 48px);
    height: 8px;
    background: ${STYLED_MACROS.BACKGROUND.DEFAULT};
  }
`;

export const ConfirmDialogContent = styled(DialogContent)`
  > p {
    display: inline-block;
    white-space: pre-line;
  }
`;

export const VenditModalContentSimple = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  min-height: 48px;
  overflow: auto;

  
  ${STYLED_MACROS.MEDIA_QUERY.NOT_MOBILE} {
    max-height: 70vh;
  }
`;

const ReferenceWrapper = styled(DefaultCard)`
  ${STYLED_MACROS.CARD_CLICKABLE};
  position: relative;

  > div > div {
    width: 100%;
    margin-bottom: ${(props) => props.theme.spacing(1)}px;
  }
`;

export const ModalReference = ({ children, ...rest }) => {
  const isMobile = useDeviceQuery('mobile');
  return (
    <ReferenceWrapper {...rest}>
      {
        children
      }
      {
        !isMobile
        && (
          <ArrowForwardIos style={{
            fontSize: 32, position: 'absolute', top: 0, bottom: 0, margin: 'auto', right: 16,
          }}
          />
        )
      }
    </ReferenceWrapper>
  );
};

export const ConfirmationDialog = ({
  open, title, content, variant, onConfirm, onCancel, confirmLabel, cancelLabel,
}) => (
  <Dialog open={open}>
    <DialogTitle>
      <TitleTypo>
        {title}
      </TitleTypo>
    </DialogTitle>
    <ConfirmDialogContent>
      {
        content
      }
    </ConfirmDialogContent>
    <DialogActions>
      <DarkButton
        disableElevation
        variant="contained"
        onClick={onCancel}
      >
        {cancelLabel || '취소'}
      </DarkButton>
      {
          variant === 'default'
          && (
            <Button
              disableElevation
              color="primary"
              variant="contained"
              onClick={onConfirm}
            >
              {confirmLabel || '확인'}
            </Button>
          )
          || variant === 'danger'
          && (
            <ErrorButton
              disableElevation
              color="primary"
              variant="contained"
              onClick={onConfirm}
            >
              {confirmLabel || '확인'}
            </ErrorButton>
          )
        }
    </DialogActions>
  </Dialog>
);
