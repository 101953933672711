import Translated from 'components/Translation';
import {
  CONSTANTS,
  IMAGE_CLOUDFRONT_HOST,
  isDevelopment,
} from 'helpers/constants';
import moment from 'moment';
import { useRef, useState } from 'react';
import shortUUID from 'short-uuid';

export const SECOND_MS = 1000;

export const MINUTE_MS = SECOND_MS * 60;

export const HOUR_MS = MINUTE_MS * 60;

export const DAY_MS = HOUR_MS * 24;

export const destructEdges = (connectionData) =>
  connectionData?.[Object.keys(connectionData)[0]]?.edges?.map(
    (edge) => edge.node,
  ) || [];

export const destructSingleField = (apolloQueryData, fallback) =>
  apolloQueryData?.[Object.keys(apolloQueryData)[0]] || fallback || {};

export const getIsPlatformReservation = (reservation) =>
  reservation.platform &&
  !['ETC', 'MANUAL', 'PHONE'].includes(reservation.platform);

export const accummulateRoomDevices = (roomDevices) =>
  roomDevices?.reduce((acc, cur) => ({ ...acc, [cur.type]: cur }), {}) || {};

export const getCurrentReservationFromRoom = ({
  upcomingReservation,
  currentReservation,
}) =>
  upcomingReservation?.status === 'ROOM_MANUALLY_ASSIGNED'
    ? upcomingReservation
    : currentReservation;

export const pageInfo = (connectionData) =>
  connectionData?.[Object.keys(connectionData)[0]]?.pageInfo;

export const hasNextPage = (connectionData) =>
  pageInfo(connectionData)?.hasNextPage;

export const getDayOfHistory = (date) =>
  moment(date).year() * 365 + moment(date).dayOfYear();

export const getRoomNights = (startDate, endDate) =>
  getDayOfHistory(endDate) - getDayOfHistory(startDate);

export const renderTranslatedOta = (ota) =>
  (ota.key && <Translated>{ota.key}</Translated>) || ota.name;

export const ACCOMMODATION_AUTHORITY_LEVEL = {
  MAID: 0,
  STAFF: 1,
  MANAGER: 5,
  OWNER: 10,
  KIOSK: 10,
};

export const isAboveAuthority = (value, authority) => {
  if (!ACCOMMODATION_AUTHORITY_LEVEL[authority]) {
    throw new Error('UNDEFINED_AUTHORITY');
  }

  return (
    ACCOMMODATION_AUTHORITY_LEVEL[value] >=
    ACCOMMODATION_AUTHORITY_LEVEL[authority]
  );
};

export const isPartiallyCancelled = (paymentData) => {
  if (!paymentData) return false;

  const { childPayments } = paymentData;

  if (!childPayments) return false;

  const allPayments = [...childPayments, paymentData];

  let paid = false;
  let cancelled = false;

  allPayments.forEach((payment) => {
    if (payment.status === 'cancelled') {
      cancelled = true;
    }

    if (payment.status === 'paid') {
      paid = true;
    }
  });

  return paid && cancelled;
};

export const withAsyncBandwidth = (
  array,
  asyncMapper,
  { bandwidth = 5 } = {},
) => {
  if (bandwidth < 2) throw new Error('Bandwidth must bigger than 1');

  const entries = [...array];
  const chainedResponses = new Array(bandwidth)
    .fill()
    .map(() => new Promise((resolve) => resolve()));
  let iterator = 0;
  let index = 0;

  while (entries.length) {
    const currentEntry = entries.pop();
    const currentIndex = index;

    const beforeResponse = chainedResponses[iterator];

    chainedResponses[iterator] = beforeResponse.then(() =>
      asyncMapper(currentEntry, currentIndex),
    );

    index += 1;
    if (iterator < bandwidth - 1) {
      iterator += 1;
    } else {
      iterator = 0;
    }
  }

  return chainedResponses;
};

export const minuteToTimeString = (minutes) =>
  `${Math.floor(minutes / 60)
    .toString()
    .padStart(2, '0')}:${(minutes % 60).toString().padStart(2, '0')}`;

export const parseTimeString = (timeString) => {
  const splitted = timeString?.split(':') || [];

  return {
    hour: parseFloat(splitted[0]),
    minute: parseFloat(splitted[1]),
  };
};

export const reduceTimesToPeriods = (mappedTimes) => {
  const periods = mappedTimes.reduce((acc, cur) => {
    const lastPeriod = acc[acc.length - 1];

    if (cur.value === CONSTANTS.PRICE_TIME_TYPES.UNSET) {
      if (lastPeriod && !lastPeriod.endTime) {
        return [
          ...acc.slice(0, acc.length - 1),
          { ...lastPeriod, endTime: cur.start },
        ];
      }
      return acc;
    }

    if (!lastPeriod || lastPeriod.endTime) {
      return [
        ...acc,
        {
          type: cur.value,
          startTime: cur.start,
          ...(cur.start === 60 * 23.5 && { endTime: 60 * 24 }),
        },
      ];
    }
    if (lastPeriod.type !== cur.value) {
      return [
        ...acc.slice(0, acc.length - 1),
        { ...lastPeriod, endTime: cur.start },
        { type: cur.value, startTime: cur.start },
      ];
    }
    if (lastPeriod && cur.start === 60 * 23.5) {
      return [
        ...acc.slice(0, acc.length - 1),
        { ...lastPeriod, endTime: cur.start + 30 },
      ];
    }
    return acc;
  }, []);

  return periods.map((period) => ({
    ...period,
    startTime: minuteToTimeString(period.startTime),
    endTime: minuteToTimeString(period.endTime),
  }));
};

export const timeStringToMinute = (timeString) => {
  const { hour, minute } = parseTimeString(timeString);

  return hour * 60 + minute;
};

export const momentSetTimeString = (
  dateOrMoment,
  timeString,
  { defaultTime } = {},
) => {
  const momentDate = moment(dateOrMoment);

  momentDate.set({
    hour: parseTimeString(timeString || defaultTime).hour,
    minute: parseTimeString(timeString || defaultTime).minute,
    second: 0,
  });

  return momentDate;
};

export const getClosestLteMomentByTimeString = (timeString) => {
  const closest = momentSetTimeString(moment(), timeString);
  if (closest < moment()) {
    return closest;
  }

  return closest.subtract(1, 'day');
};

export const subtractFromTodayDateOnly = (subtract, format) =>
  (format && moment().subtract(subtract, 'day').format('YYYY-MM-DD')) ||
  moment().subtract(subtract, 'day');

export const momentAddTimeString = (dateOrMoment, timeString) => {
  const momentDate = moment(dateOrMoment);

  momentDate
    .add(parseTimeString(timeString).hour, 'hour')
    .add(parseTimeString(timeString).minute, 'minute');

  return momentDate;
};

export const numberizeTimeString = (timeString) => {
  const { hour, minute } = parseTimeString(timeString);

  return parseFloat(hour) * 100 + parseFloat(minute);
};

export const denumberizeTimeString = (timeNumber) => {
  const stringNumber = timeNumber.toString();

  if (stringNumber.length === 3) {
    return `0${stringNumber.slice(0, 1)}:${stringNumber.slice(1, 3)}`;
  }

  return `${stringNumber.slice(0, 2)}:${stringNumber.slice(2, 4)}`;
};

export const getReservationNumber = (reservation) =>
  `${reservation?.number?.toString().padStart(4, '0') || ''}`;

export const getGQLFieldName = (gqlDoc) =>
  gqlDoc?.definitions?.[0]?.name?.value;

export const getArgumentsFromReadField = (readField) => {
  try {
    return JSON.parse(readField.split(':').slice(1).join(':'));
  } catch (err) {
    return null;
  }
};

export const getErrorCode = (errorOrMessage) => {
  const errorMessage =
    typeof errorOrMessage === 'string'
      ? errorOrMessage
      : errorOrMessage.message;
  try {
    const errorCode = errorMessage.split(' ').pop();

    if (/ERR/.test(errorCode)) {
      return errorCode;
    }

    return errorMessage;
  } catch (err) {
    return errorMessage;
  }
};

export const getDotType = (status) => {
  switch (true) {
    case /normal/.test(status): {
      return 'success';
    }
    case /disabled/.test(status): {
      return 'disabled';
    }
    case /error/.test(status): {
      return 'error';
    }
    case /pending/.test(status): {
      return 'warning';
    }
    default: {
      return 'disabled';
    }
  }
};

export const minutesToHoursAndMinutesLocale = (
  minutes,
  { isNumeric, isFuture } = {},
) =>
  `${isFuture && minutes / 60 >= 24 ? '익일 ' : ''}${
    Math.floor(minutes / 60) % 24
  }시${isNumeric ? '간' : ''}${minutes % 60 ? ` ${minutes % 60}분` : ''}`;

export const extractFloor = (roomName) => {
  const roomNumber = roomName.split(/[^0-9]/).filter((val) => val)[0];

  return roomNumber?.slice(0, roomNumber.length - 2) || 0;
};

export const getBaseReservationType = (type) =>
  type === 'rent' ? 'rent' : 'lodge';

export const getPaymentType = (payment) => {
  if (!payment) return null;

  const { childPayments } = payment;

  const { type } = payment;

  for (const childPayment of childPayments || []) {
    if (childPayment.type !== type && childPayment.amount) {
      return 'combined';
    }
  }

  return type;
};

export const getRoomIndicatorType = (status, saleState, isLongTerm) => {
  if (saleState === 'DISABLED') {
    return { type: 'disabled' };
  }
  switch (true) {
    case /available/i.test(status): {
      return { type: 'available' };
    }
    case ['expired', 'expiredEst'].includes(status): {
      return { type: 'error', left: true };
    }
    case /disabled|expired/i.test(status): {
      return { type: 'error' };
    }
    case /needCleaning/i.test(status): {
      return { type: 'success' };
    }
    case /error|urgentCleaning/i.test(status): {
      return { type: 'error', blink: true };
    }
    case status === 'usingUnknown': {
      return { type: 'warning', blink: true };
    }
    case /lodge/i.test(status): {
      if (isLongTerm) {
        return { type: 'longTerm', left: /left/.test(status) };
      }

      return { type: 'warning', left: /left/.test(status) };
    }
    case /selected/.test(status): {
      return { type: 'warning', blink: true };
    }
    case /rent/i.test(status): {
      return { type: 'info', left: /left/.test(status) };
    }
    case /powerDown/i.test(status): {
      return { type: 'error', blink: true };
    }
    case status === 'cleaning': {
      return { type: 'success', blink: true };
    }
    default: {
      return { type: 'disabled' };
    }
  }
};

export const getKioskIndicatorType = (status) => {
  switch (status) {
    case 'ACTIVE': {
      return 'success';
    }
    case 'STOPPED': {
      return 'error';
    }
    case 'MAINTENANCE': {
      return 'warning';
    }
    case 'ERROR': {
      return 'error';
    }
    default: {
      return 'default';
    }
  }
};

export const getDoorIndicatorType = (status) => {
  switch (status) {
    case true: {
      return 'warning';
    }
    case false: {
      return 'default';
    }
    default: {
      return 'default';
    }
  }
};

export const numberWithCommas = (x, postfix, { float = false } = {}) =>
  (typeof x === 'number' &&
    `${
      (float && x) ||
      Math.floor(x)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }${postfix || ''}`) ||
  '';

export const onEnter = (callback) => (event) => {
  if (event.keyCode === 13) {
    callback();
  }
};

export const withPreventDefault =
  (originFunc) =>
  (event, ...args) => {
    if (event.preventDefault) {
      event.preventDefault();
    }
    if (event.stopPropagation) {
      event.stopPropagation();
    }

    return originFunc(event, ...args);
  };

const ARTICLE_ATTACHMENT_HOST = isDevelopment ? 'articlesDev' : 'articles';

export const buildArticleAttachmentSrc = (articleId, attachment) =>
  `${IMAGE_CLOUDFRONT_HOST}/${ARTICLE_ATTACHMENT_HOST}/${articleId}/${attachment}`;

export const humanizeDate = (dateString, options) => {
  const momentDate = moment(dateString);
  const { forceShowYear } = options || {};
  const dateDifference = moment.duration(moment().diff(momentDate));
  if (dateDifference.asHours() < 1) {
    return (
      <Translated values={[dateDifference.humanize()]}>
        {`${'${1}'} ${momentDate > moment() ? '후' : '전'}`}
      </Translated>
    );
  }
  if (moment().dayOfYear() === momentDate.dayOfYear()) {
    return (
      <Translated values={[momentDate.format('HH:mm')]}>
        {'오늘 ${1}'}
      </Translated>
    );
  }
  if (moment() - momentDate < 1000 * 60 * 60 * 24 * 365 && !forceShowYear) {
    return momentDate.format('MM-DD HH:mm');
  }
  if (forceShowYear) {
    return momentDate.format('YYYY/MM/DD HH:mm:ss');
  }
  return momentDate.format('YYYY/MM/DD');
};

export const formatPhone = (phone) => {
  if (/010[0-9]{8}/.test(phone)) {
    return `${phone.slice(0, 3)}-${phone.slice(3, 7)}-${phone.slice(7, 11)}`;
  }

  return phone;
};

export const bundleArray = (array, bundleSize = 2) =>
  array.reduce((prev, content, idx) => {
    if (idx % bundleSize === 0) {
      prev.push([content]);
      return prev;
    }

    prev[Math.floor(idx / bundleSize)].push(content);
    return prev;
  }, []);

export const destructPriceFromRoomType = ({ currentPrice } = {}) => {
  if (!currentPrice) return { rentPrice: null, lodgePrice: null };

  return currentPrice.reduce(
    (acc, cur) => ({ ...acc, [`${cur.priceType.toLowerCase()}Price`]: cur }),
    { lodgePrice: null, rentPrice: null },
  );
};

export const truncateString = (targetString, elllipsisAfter) => {
  if (!targetString) return false;
  if (targetString.length <= elllipsisAfter) {
    return targetString;
  }

  return `${targetString.substring(0, elllipsisAfter)}..`;
};

export function isValidDate(date) {
  // eslint-disable-next-line no-restricted-globals
  return date instanceof Date && !isNaN(date);
}

export const parseNumberWithCommas = (amount) =>
  parseFloat(amount?.toString?.().replace(/,/g, '')) || 0;

export const restrictInputNumberOnly =
  ({
    withComma,
    onChange,
    maxValue,
    minValue,
    numeric,
    formatOnMinMax = true,
    allowMinus = false,
    float = false,
  }) =>
  (event) => {
    if (event.target.value.split('').pop() === '.')
      return onChange(event.target.value);

    const amountValue = numeric
      ? parseNumberWithCommas(event.target.value)
      : event.target.value?.replace?.(/[^0-9]/g, '');

    if (minValue && amountValue < minValue) {
      event.target.onblur = () => onChange(formatValue(minValue)); // eslint-disable-line
    } else {
      event.target.onblur = null; // eslint-disable-line
    }

    if (
      /-/.test(event.target.value) &&
      /^[0-]{1,2}$/.test(event.target.value) &&
      allowMinus
    ) {
      return onChange('-');
    }

    const formatValue = (targetVal) =>
      withComma
        ? numberWithCommas(targetVal || amountValue, null, { float })
        : targetVal || amountValue;

    if (!minValue || amountValue > minValue) {
      if (maxValue && amountValue < maxValue) {
        return onChange(formatValue());
      }
      if (maxValue) {
        if (!formatOnMinMax) return;
        return onChange(formatValue(maxValue));
      }
    }

    if (minValue && amountValue > minValue) {
      return onChange(formatValue());
    }

    if (minValue) {
      if (!formatOnMinMax) return;

      return onChange(formatValue());
    }
    return onChange(formatValue());
  };

export class Debounce {
  constructor() {
    this.debounceRejector = null;
  }

  asyncDebounce = async (timeout) => {
    if (this.debounceRejector) this.debounceRejector();

    return new Promise((resolve) => {
      this.debounceRejector = () => resolve(false);

      setTimeout(() => resolve(true), timeout);
    });
  };
}

export const getUUIDFromShorten = (shorten) => {
  const translator = shortUUID();
  return translator.toUUID(shorten);
};
