import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { getAccommodationMetaInfoMaidQuery } from 'graphql/accommodation';
import { getArticlesQuery } from 'graphql/article';
import { Debounce, destructSingleField, getErrorCode } from 'helpers';
import { CONSTANTS } from 'helpers/constants';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import Translated from 'components/Translation';
import { useSnackbar } from 'notistack';
import { getReservationSettingByGuestSessionQuery } from 'graphql/reservations';
import authHandler from 'helpers/authHandler';
import { getSingleReservationByGuestSessionQuery } from 'graphql/thirdParty';
import { useRecoilState } from 'recoil';
import { permanentStateAtom } from 'helpers/atoms';
import {
  getConciergeServiceSettingByGuestSessionQuery,
  getConciergeWorkSettingsQuery,
} from 'graphql/concierge';

export { useSnackbar } from 'notistack';

export const useDebounce = () => {
  const [asyncDebouncer] = useState(new Debounce());

  return asyncDebouncer;
};

export const useMyAuthority = (accId) => {
  const { id: myId } = authHandler?.user || {};
  const { accommodationId } = useParams();

  const { data } = useQuery(
    ((accId || accommodationId) && getAccommodationMetaInfoMaidQuery) ||
      getArticlesQuery,
    {
      variables: {
        id: accommodationId || accId,
      },
    },
  );

  if (!accommodationId && !accId) {
    return null;
  }

  return destructSingleField(data)?.employees?.find(
    (employee) => employee.id === myId,
  )?.authority;
};

export const useDeviceQuery = (device) => {
  const isQueriedDevice = useMediaQuery(
    device === 'tiny'
      ? `(max-width: ${CONSTANTS.TINY_DEVICE_WIDTH}px)`
      : (theme) =>
          theme.breakpoints.down(
            (device === 'phone' && 'xs') ||
              (device === 'mobile' && 'sm') ||
              (device === 'compact' && 'md') ||
              'xl',
          ),
  );

  return isQueriedDevice;
};

export const useInput = (initialInput = {}) => {
  const [initState, setInitState] = useState(initialInput);
  const [input, setInput] = useState(initialInput);

  const initialize = useCallback(() => {
    setInput(initState);
  }, [initState]);

  const onChangeInput = useCallback(
    (key, value) => {
      const keys = key.split('.');

      if (keys.length) {
        const newInput = { ...input };

        let targetInputParent = newInput;
        let originInputParent = input;
        let targetKey;

        keys.forEach((_key, idx) => {
          if (idx !== keys.length - 1) {
            targetInputParent[_key] = {
              ...input[_key],
            };

            originInputParent = originInputParent[_key];
            targetInputParent = targetInputParent[_key];
          } else {
            targetKey = _key;
          }
        });

        targetInputParent[targetKey] = value;

        setInput(newInput);

        return newInput;
      }

      const newInput = {
        ...input,
        [key]: value,
      };
      setInput(newInput);

      return newInput;
    },
    [input],
  );

  const isChanged = useMemo(() => {
    let _isChanged = false;

    const recurseCompare = (currentDepth, operand) => {
      if (_isChanged) return;

      // console.log(currentDepth, operand);

      if (typeof currentDepth !== typeof operand) {
        _isChanged = true;
        return;
      }

      if (currentDepth && typeof currentDepth === 'object') {
        if (
          Object.getPrototypeOf(currentDepth).constructor.name === 'Object' ||
          Array.isArray(currentDepth)
        ) {
          if (
            Object.keys(currentDepth).length !== Object.keys(operand).length
          ) {
            _isChanged = true;
            return;
          }
          Object.keys(currentDepth).forEach((key) => {
            recurseCompare(currentDepth[key], operand[key]);
          });

          return;
        }

        if (moment.isDate(currentDepth) || moment.isMoment(currentDepth)) {
          if (!moment(currentDepth).isSame(operand)) {
            _isChanged = true;
          }
          return;
        }
      }

      if (currentDepth !== operand) {
        _isChanged = true;
      }
    };
    recurseCompare(input, initState);
    return _isChanged;
  }, [input, initState]);

  return [
    input,
    onChangeInput,
    {
      setInput,
      initialize,
      setInitState,
      isChanged,
      initState,
    },
  ];
};

export const useNotifyFeedback = () => {
  const { enqueueSnackbar } = useSnackbar();

  const notifySuccess = (message) =>
    enqueueSnackbar(message, { variant: 'success' });

  const notifyError = (err) => {
    console.error(err);

    if (err.force) {
      return enqueueSnackbar(err.message, { variant: 'error' });
    }
    if (err === 'UNSAVED_CHANGE') {
      return enqueueSnackbar(<Translated>ERR_UNSAVED_CHANGE</Translated>, {
        variant: 'error',
      });
    }

    if (/Validation notEmpty/.test(err?.message || err)) {
      return enqueueSnackbar(
        '누락된 입력값이 있습니다. 모든 입력을 완료해 주세요.',
        { variant: 'error' },
      );
    }

    if (/401 Access denied/.test(err?.message || err)) {
      return enqueueSnackbar('작업을 실행할 권한이 없습니다.', {
        variant: 'error',
      });
    }

    const errorCode = getErrorCode(err);

    switch (errorCode) {
      case '400': {
        return enqueueSnackbar(
          `잘못된 요청입니다. 입력을 확인해 주세요. 에러코드: ${errorCode}`,
          { variant: 'error' },
        );
      }
      case 'ERR_DUPLICATED_DATA_FOUND': {
        return enqueueSnackbar(
          `중복된 데이터가 존재합니다. 에러코드: ${errorCode}`,
          { variant: 'error' },
        );
      }
      case 'ERR_DATA_NOT_FOUND_ON_ID': {
        return enqueueSnackbar(
          `유효하지 않은 정보입니다. 에러코드: ${errorCode}`,
          { variant: 'error' },
        );
      }
      default: {
        return enqueueSnackbar(
          `알 수 없는 에러가 발생했습니다. 에러코드: ${errorCode}`,
          { variant: 'error' },
        );
      }
    }
  };

  return [notifySuccess, notifyError, { enqueueSnackbar }];
};

/**
 * @param {any} defaultState
 * @param {string} permanentKey
 * @returns {[any, (newValue: any) => void]}
 */
export const usePermanentState = (defaultState, permanentKey) => {
  const [permanentStateSubscription, setPermanentStateSubscription] =
    useRecoilState(permanentStateAtom);
  const [latestSubscriptionTimestamp, setLatestSubscriptionTimestamp] =
    useState(null);
  const [permanentValue, _setPermanentValue] = useState(
    localStorage.getItem(permanentKey) || defaultState,
  );

  useEffect(() => {
    if (
      permanentStateSubscription[permanentKey] &&
      latestSubscriptionTimestamp !== permanentStateSubscription[permanentKey]
    ) {
      setLatestSubscriptionTimestamp(permanentStateSubscription[permanentKey]);
      _setPermanentValue(localStorage.getItem(permanentKey));
    }
  }, [permanentStateSubscription, latestSubscriptionTimestamp]);

  const setPermanentValue = (newValue) => {
    _setPermanentValue(newValue);
    setPermanentStateSubscription({
      ...permanentStateSubscription,
      [permanentKey]: new Date().getTime(),
    });

    if (newValue) {
      localStorage.setItem(permanentKey, newValue);
    } else {
      localStorage.removeItem(permanentKey);
    }
  };

  return [permanentValue, setPermanentValue];
};

export const useGuestReservationQuery = () => {
  const { reservationId } = useParams();
  const [sessionToken, setSessionToken] = usePermanentState(
    null,
    `${reservationId}_AUTH_TOKEN`,
  );

  const { data, loading, error } = useQuery(
    getSingleReservationByGuestSessionQuery,
    {
      variables: {
        reservationId,
        sessionToken,
      },
    },
  );

  const reservation = useMemo(() => destructSingleField(data), [data]);

  return {
    reservation,
    data,
    loading,
    error,
    reservationId,
    sessionToken,
    setSessionToken,
  };
};

export const useReservationSettingsByGuestSession = () => {
  const { reservationId } = useParams();
  const [sessionToken] = usePermanentState(null, `${reservationId}_AUTH_TOKEN`);

  const {
    data: reservationSettingData,
    loading,
    error,
    refetch,
    ...rest
  } = useQuery(getReservationSettingByGuestSessionQuery, {
    variables: {
      reservationId,
      sessionToken,
    },
  });

  return [
    reservationSettingData?.getReservationSettingByGuestSession,
    {
      loading,
      error,
      refetch,
      ...rest,
    },
  ];
};

export const useConciergeServiceSetting = () => {
  const { reservationId } = useParams();
  const [accommodationId] = usePermanentState(
    null,
    `${reservationId}_ACCOMMODATION_TOKEN`,
  );
  const [sessionToken] = usePermanentState(null, `${reservationId}_AUTH_TOKEN`);

  const {
    data: conciergeServiceSetting,
    loading,
    error,
    refetch,
    ...rest
  } = useQuery(getConciergeServiceSettingByGuestSessionQuery, {
    variables: {
      input: {
        reservationId,
        sessionToken,
        accommodationId,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  return [
    conciergeServiceSetting?.getConciergeServiceSettingByGuestSession,
    {
      loading,
      error,
      refetch,
      ...rest,
    },
  ];
};

export const useOpenDropdown = (initailValue) => {
  const [isOpen, setIsOpen] = useState(initailValue || false);
  const onOpened = useCallback((value) => {
    setIsOpen(value);
  }, []);

  return [isOpen, onOpened];
};

export const usePagination = () => {
  const [pagination, setPagination] = React.useState({
    hasNextPage: false,
    hasPreviousPage: false,
    totalCount: 0,
    totalPages: 0,
    last: false,
    first: false,
    page: 1,
    limit: 6,
  });

  const onUpdate = React.useCallback((updatedPagination) => {
    setPagination((initialPagination) => ({
      ...initialPagination,
      ...updatedPagination,
    }));
  }, []);

  return { pagination, setPagination: onUpdate };
};

export const usePaymentOption = () => {
  const [conciergeSetting] = useConciergeServiceSetting();
  const [paymentOptions, setPaymentOptions] = React.useState([]);

  const initialLoad = () => {
    if (conciergeSetting) {
      const newOptions = [];

      const { onSitePaymentByCard, onSitePaymentByCash } = conciergeSetting;

      if (onSitePaymentByCard) {
        newOptions.push({
          rule: 'DIRECT_CARD',
          name: '신용/체크카드',
          icon: 'card',
        });
      }

      if (onSitePaymentByCash) {
        newOptions.push({
          rule: 'DIRECT_CASH',
          name: '현금결제',
          icon: 'cash',
        });
      }

      setPaymentOptions(newOptions);
    }
  };

  React.useEffect(() => {
    initialLoad();
  }, [conciergeSetting]);

  return { paymentOptions };
};

export const useConciergeWorkSetting = () => {
  const { reservationId } = useParams();

  const [saveAccommodationId] = usePermanentState(
    null,
    `${reservationId}_ACCOMMODATION_TOKEN`,
  );
  const { data } = useQuery(getConciergeWorkSettingsQuery, {
    variables: { accommodationId: saveAccommodationId },
  });

  return destructSingleField(data, []);
};

/**
 * @param {number} weekDay
 * @returns {string}
 */
function refineWeekDayToString(weekDay) {
  switch (weekDay) {
    case 0:
      return 'sun';
    case 1:
      return 'mon';
    case 2:
      return 'tue';
    case 3:
      return 'wed';
    case 4:
      return 'thu';
    case 5:
      return 'fri';
    case 6:
      return 'sun';
    default:
      return '';
  }
}

export const useProductStatusInConcierge = (props) => {
  const [conciergeSetting] = useConciergeServiceSetting();
  const workSettings = useConciergeWorkSetting();
  const { isRestTime, breakEndTime } = useMemo(() => {
    const restTimes =
      workSettings.filter(
        (work) =>
          work?.weekDay ===
            refineWeekDayToString(moment().subtract(8, 'hours').weekday()) &&
          /^REST/.test(work.workType),
      ) || [];
    let _breakEndTime;
    const checkedRestTimeValues = restTimes
      ?.reduce((acc, cur) => {
        const [startTime, endTime] = [
          cur.value.slice(0, 4),
          cur.value.slice(4),
        ];
        const now = moment();
        if (startTime < endTime) {
          if (
            now.isSameOrAfter(moment(startTime, 'HHmm')) &&
            now.isSameOrBefore(moment(endTime, 'HHmm'))
          ) {
            _breakEndTime = moment(endTime, 'HHmm');
            acc.push(true);
            return acc;
          }
          acc.push(false);
          return acc;
        }
        if (
          now.isSameOrAter(
            moment(startTime, 'HHmm').subtract(now.hour() > 0 ? 1 : 0, 'day'),
          ) &&
          now.isSameOrBefore(
            moment(endTime, 'HHmm').add(now.hour > 8 ? 1 : 0, 'day'),
          )
        ) {
          acc.push(true);
          _breakEndTime = moment(endTime, 'HHmm').add(
            now.hour > 8 ? 1 : 0,
            'day',
          );
          return acc;
        }
        acc.push(false);
        _breakEndTime = null;
        return acc;
      }, [])
      .filter(Boolean);
    return {
      isRestTime: checkedRestTimeValues.length > 0 && restTimes.length > 0,
      breakEndTime: _breakEndTime,
    };
  }, [workSettings]);
  const isClosed = useMemo(() => {
    const workTimes =
      workSettings.filter(
        (work) =>
          work?.weekDay ===
            refineWeekDayToString(moment().subtract(8, 'hours').weekday()) &&
          /^WORK/.test(work.workType),
      ) || [];
    if (!workTimes?.[0]?.value) {
      return true;
    }
    if (workTimes?.[0]?.value === '00000000') {
      return false;
    }
    const [startTime, endTime] = [
      workTimes?.[0]?.value?.slice(0, 4) || '0000',
      workTimes?.[0]?.value?.slice(4) || '0000',
    ];
    const nowTime = moment();
    const nowSaleDate = moment().subtract(8, 'h').format('YYYYMMDD');
    let startDate = moment(`${nowSaleDate}${startTime}`, 'YYYYMMDDHHmm');
    let endDate = moment(`${nowSaleDate}${endTime}`, 'YYYYMMDDHHmm');
    if (startTime > endTime) {
      startDate = moment(`${nowSaleDate}${startTime}`, 'YYYYMMDDHHmm');
      endDate = moment(`${nowSaleDate}${endTime}`, 'YYYYMMDDHHmm').add(1, 'd');
    }
    return nowTime.isAfter(endDate) || nowTime.isBefore(startDate);
  }, [workSettings]);
  const nextWorkTime = useMemo(() => {
    const nowDate = moment().format('YYYYMMDD');
    if (isClosed) {
      const todayOpenDate = moment(
        `${nowDate}${
          (workSettings.filter(
            (work) =>
              work?.weekDay ===
                refineWeekDayToString(
                  moment().subtract(8, 'hours').weekday(),
                ) && /^WORK/.test(work.workType),
          ) || [])?.[0]?.value?.slice(0, 4) || '0000'
        }`,
        'YYYYMMDDHHmm',
      );
      const isBeforeOpen = todayOpenDate.isAfter(moment());
      if (isBeforeOpen) {
        return todayOpenDate;
      }
    }
    const workTimes =
      workSettings.filter(
        (work) =>
          work?.weekDay ===
            refineWeekDayToString(
              moment().add(1, 'day').subtract(8, 'hours').weekday(),
            ) && /^WORK/.test(work.workType),
      ) || [];
    return workTimes?.[0]?.value === '00000000'
      ? moment(`${nowDate}0800`, 'YYYYMMDDHHmm')
      : moment(
          `${nowDate}${workTimes?.[0]?.value?.slice(0, 4) || '0800'}`,
          'YYYYMMDDHHmm',
        );
  }, [workSettings]);

  const isTempWorkingStop = moment(conciergeSetting?.tempWorkingStop).isAfter(
    moment(),
  );

  const isRoomServiceActivated =
    conciergeSetting?.roomServiceActivated &&
    !moment(conciergeSetting?.roomServiceActivated).isAfter(moment());
  const isAmenityActivated =
    conciergeSetting?.amenityActivated &&
    !moment(conciergeSetting?.amenityActivated).isAfter(moment());

  const isSoldOut =
    props?.data?.soldOutPeriod &&
    moment(props.data.soldOutPeriod).isAfter(moment());
  const result = {
    isRoomServiceDisabled:
      isClosed ||
      isTempWorkingStop ||
      isRestTime ||
      !isRoomServiceActivated ||
      isSoldOut,
    isAmenityDisabled:
      isClosed ||
      isTempWorkingStop ||
      isRestTime ||
      !isAmenityActivated ||
      isSoldOut,
    tempWorkingStop: moment(conciergeSetting?.tempWorkingStop),
    isTempWorkingStop,
    isRoomServiceActivated,
    isAmenityActivated,
    isRestTime,
    breakEndTime,
    isClosed,
    nextWorkTime,
  };

  return result;
};
